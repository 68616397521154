import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Machine} from '../Models/Machine';
import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';

import _moment from 'moment';
const moment = _moment;

@Injectable({
	providedIn: 'root'
})
export class DashboardMachineService {

	header;
	apiUrl = environment.apiUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	getMachines(): Observable<Machine[]> {
		return this.http.get<Machine[]>(this.apiUrl + '/machines', {headers: this.header});
	}

	async getLastAnalysis(machine_id: number) {
		const last_analysis = await this.http.get<any>(this.apiUrl + '/analyses?machine_id=' + machine_id + '&lastAnalysis=1', {headers: this.header}).toPromise();
		return last_analysis[0]
	}

	async getNbOfAnalysisSince(machine_id: number, last_maintenance: string) {
		return await this.http.get<any>(this.apiUrl + '/analyses?machine_id=' + machine_id + '&after=' + last_maintenance, {headers: this.header}).toPromise();
	}

	async getVariableValue(analysis_id: number, variable_ids: number[]) {
		return await this.http.get<any>(this.apiUrl + '/analyses/' + analysis_id + '/variables/' + variable_ids, {headers: this.header}).toPromise();
	}

	async getNextMaintenanceDate(machineId, lastMaintenanceDate, nbAnalysisBeforeMaintenance) {
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
		let nextMaintenanceDate = null;
		let daysNextMaintenance = null;
		let nbAnalSinceMaint = null;
		let nbDaysSinceMaintenance = null;
		if (lastMaintenanceDate != null){
			nbDaysSinceMaintenance = Math.floor((new Date().getTime() - new Date(lastMaintenanceDate).getTime()) / oneDay);
			if (nbDaysSinceMaintenance > 365) {
				nextMaintenanceDate = 'Overdue';
			} else {
				const nbAnalysisSinceMaintenance = await this.getNbOfAnalysisSince(machineId, lastMaintenanceDate);
				nbAnalSinceMaint = nbAnalysisSinceMaintenance.length;
				if ( nbAnalSinceMaint > nbAnalysisBeforeMaintenance) {
					nextMaintenanceDate = 'Overdue';
				} else {
					const averageAnalysisPerDay = nbAnalSinceMaint / nbDaysSinceMaintenance;
					const remainingAnalysis = nbAnalysisBeforeMaintenance - nbAnalSinceMaint;
					const nbDaysForAnalysis = Math.ceil(remainingAnalysis / averageAnalysisPerDay);
					const nbDaysFor365 = 365 - nbDaysSinceMaintenance;
					daysNextMaintenance = nbDaysForAnalysis < nbDaysFor365 ? nbDaysForAnalysis : nbDaysFor365;
					nextMaintenanceDate = moment().add(daysNextMaintenance, 'd').format('YYYY-MM-DD');
				}
			}
		}
		return {
			'daysNextMaintenance': daysNextMaintenance,
			'nextMaintenanceDate': nextMaintenanceDate,
			'nbAnalysisSinceMaintenance': nbAnalSinceMaint,
			'nbDaysSinceMaintenance': nbDaysSinceMaintenance
		};
	}
}
