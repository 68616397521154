import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Modification} from '../../Models/Modification';

@Component({
	selector: 'app-edit-approval-dialog',
	templateUrl: './edit-approval-dialog.component.html',
	styleUrls: ['./edit-approval-dialog.component.css']
})
export class EditApprovalDialogComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<EditApprovalDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: Modification[]) {
	}

	ngOnInit(): void {
	}

}
