import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieServiceService} from '../cookie-service.service';

@Injectable({
	providedIn: 'root'
})
export class FullscreenChartService {

	header;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST,PATCH, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}
}
