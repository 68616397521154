<body>
<mat-card class="machine-list-container">
	<mat-card-title fxLayoutAlign="space-between">
		<h2>Machines dashboard</h2>
	</mat-card-title>

	<div class="machine-list">
		<div class="sorter">
			<button mat-raised-button color="primary" (click)="orderByMachineTag()" [disabled]="MachineLoading">
				<mat-icon [style]="orderedByIdMachine ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="ascIdMachine && orderedByIdMachine">keyboard_arrow_up</mat-icon>
				<mat-icon [style]="orderedByIdMachine ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="!ascIdMachine && orderedByIdMachine">keyboard_arrow_down</mat-icon>
				Machine ID
			</button>

			<button mat-raised-button color="primary" (click)="orderByDaysWithoutAnalysis()" [disabled]="MachineLoading">
				<mat-icon [style]="orderedByDaysWithoutAnalysis ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="ascDaysWithoutAnalysis  && orderedByDaysWithoutAnalysis">keyboard_arrow_up</mat-icon>
				<mat-icon [style]="orderedByDaysWithoutAnalysis ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="!ascDaysWithoutAnalysis && orderedByDaysWithoutAnalysis">keyboard_arrow_down</mat-icon>
				Days without analysis
			</button>

			<button mat-raised-button color="primary" (click)="orderByCartDisp()" [disabled]="MachineLoading">
				<mat-icon [style]="orderedByCartDisp ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="ascCartDisp && orderedByCartDisp">keyboard_arrow_up</mat-icon>
				<mat-icon [style]="orderedByCartDisp ? {'visibility':'visible'}:{'visibility':'hidden'}" *ngIf="!ascCartDisp && orderedByCartDisp">keyboard_arrow_down</mat-icon>
				Cartridges available
			</button>
		</div>

		<mat-divider></mat-divider>

		<mat-progress-bar *ngIf="MachineLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>

		<ul class="machines">
			<li *ngFor="let machine of machinesList; let i = index">
				<mat-card class="card">
					<mat-card-header style="height: 60px" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
						<mat-card-title style="margin-bottom: 0" fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start center">
							{{ machine?.machine_tag  }}
						</mat-card-title>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded" [ngStyle]="
              machine.state == 'standby'?{'border-color':'#000000'} : {'border-color':'#D5D5D5'}">
							<p>State:
								<ng-container *ngIf="machine.state != null; else defaultValue">
									{{machine.state}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded" [ngStyle]="
              machine.days_without_analysis == null?{'border-color':'#D5D5D5'} :
              (machine.days_without_analysis == 3 || machine.days_without_analysis == 4)?{'border-color':'#F9D054'} :
              machine.days_without_analysis>4?{'border-color':'#E0242F'} :
              {'border-color': 'green'}">
							<p>Days without analysis:
								<ng-container *ngIf="machine.days_without_analysis != null; else defaultValue">
									{{machine.days_without_analysis}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded" [ngStyle]="
               machine.cartouches_disponibles == null?{'border-color':'#D5D5D5'} :
               machine.cartouches_disponibles > 3 ? {'border-color': 'green'}:
               machine.cartouches_disponibles > 2 ? {'border-color':'#F9D054'} : {'border-color':'#E0242F'}">
							<p>Cartridges available:
								<ng-container *ngIf=" machine.cartouches_disponibles != null; else defaultValue">
									{{ machine.cartouches_disponibles}}
								</ng-container>
							</p>
						</mat-card>

						<mat-card class="general-machine-info" fxLayout="column" fxLayoutAlign="center center" *ngIf="machine.flagsLoaded && checkForPermission()" [ngStyle]="
              machine.nextMaintenanceDate == null?{'border-color':'#D5D5D5'} :
              machine.nextMaintenanceDate == 'Overdue'?{'border-color':'#E0242F'} :
              machine.daysNextMaintenance > 60?{'border-color': 'green'}:
              {'border-color':'#F9D054'}">
							<p>Next maintenance:
								<ng-container *ngIf="machine.nextMaintenanceDate != null; else defaultValue">
									{{machine.nextMaintenanceDate}}
								</ng-container>
							</p>
						</mat-card>
						<ng-template #defaultValue>No Data</ng-template>
						<span fxFlex></span>
					</mat-card-header>
				</mat-card>
			</li>
		</ul>
	</div>
</mat-card>
</body>


