// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	adminUrl: 'https://api.bioalert.io/dev',
	apiUrl: 'https://api-gateway-b9c1mmmw.ue.gateway.dev/dev',
	authUrl: 'https://api.bioalert.io/dev'
};
