import {Injectable} from "@angular/core";
import {
	HTTP_INTERCEPTORS,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from "@angular/common/http";
import {CookieServiceService} from "./cookie-service.service";
import {Observable, throwError} from "rxjs";
import {catchError, finalize, retry} from "rxjs/operators";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private cookieServices: CookieServiceService, private router: Router, private snackbar: MatSnackBar) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		request = request.clone({
			headers: request.headers.set('Authorization',
				'Bearer ' + this.cookieServices.get('token'))
		});
		return next.handle(request).pipe(
			catchError(err => {
				if (err instanceof HttpErrorResponse){

					if (err.status === 401){
						//redirect to login...
						this.snackbar.open('Non autorisé, veuillez-vous reconnecter ' + err.message, 'Ok');
						this.router.navigate(['/login']);
					}
					// return the error back to the caller
					return throwError(err);
				}
			}), finalize(() => {
				//clean up
			})
		);
	}
}

export const AuthInterceptorProvider = {
	provide : HTTP_INTERCEPTORS,
	useClass: AuthInterceptor,
	multi: true,
};

