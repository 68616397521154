import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MachineListComponent} from './dashboard-machine/machine-list/machine-list.component';
import {AuthGuard} from './auth.guard';
import {LoginComponent} from './login/login.component';
import {AccountPageComponent} from './account-page/account-page.component';
import {AnalysisListComponent} from "./dashboard-analysis/analysis-list/analysis-list.component";


const routes: Routes = [
	{ path: '', redirectTo: 'dashboards/machines', pathMatch: 'full' },
	{path: 'account', component: AccountPageComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/machines', component: MachineListComponent, canActivate: [AuthGuard]},
	{path: 'dashboards/analysis', component: AnalysisListComponent, canActivate: [AuthGuard]},
	{path: '**', component: LoginComponent},
	{path: 'login', component: LoginComponent}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
