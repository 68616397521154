import {Role} from "./Role";

export class User {

    public user_id: string;
    public user_email: string;
    public user_name: string;
    public user_password: string;
    public user_business: number;
    public user_role: number;
    public user_roleName: string;
    public access_level: number;

    constructor(user_id: string, user_email: string, user_name: string, user_password: string, user_business: number, user_role: number, user_roleName: string, access_level: number) {
        this.user_id = user_id;
        this.user_email = user_email;
        this.user_name = user_name;
        this.user_password = user_password;
        this.user_business = user_business;
        this.user_role = user_role;
        this.access_level = access_level;
    }
}