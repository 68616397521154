import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';
import {User} from "../Models/User";

@Injectable({
	providedIn: 'root'
})
export class AccountPageService {

	header;
	adminUrl = environment.adminUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, PATCH, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	updateUser(user: User): Observable<any> {
		return this.http.patch<any>(this.adminUrl + '/admin/users', user, {headers: this.header});
	}

	resetPassword(email: string): Observable<any> {
		return this.http.get<any>(this.adminUrl + '/admin/users/resetPassword?email=' + email, {headers: this.header});
	}
}
