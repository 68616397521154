import {Component, OnInit} from '@angular/core';
import {ExportToCsv} from 'export-to-csv';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomChartData} from 'src/app/ChartFactories/CustomChartData';
import {FullscreenChartService} from 'src/app/fullscreen-chart/fullscreen-chart.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UgChartFactory} from "../../ChartFactories/ChartFactory/ConcreteFactories/ug-chart-factory";
import {ChartFactory} from 'src/app/ChartFactories/ChartFactory/ChartFactory';
import {DashboardAnalysisService} from	'../dashboard-analysis.service'
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import moment from 'moment';


@Component({
	selector: 'app-analysis-list',
	templateUrl: './analysis-list.component.html',
	styleUrls: ['./analysis-list.component.css']
})

export class AnalysisListComponent implements OnInit {
	analysisList: any[] = [];
	analysisLoading: boolean = false;
	displayedColumns: string[] = ['analysis_id', 'machine_tag', 'sampling_point_tag', 'analysis_time', 'sampling_time', 'ug_value', 'risk_lvl'];
	dataSource;

	currentUnit: string = 'UG/ml';
	unitList: string[] = ['UG/ml', 'UG/L'];
	unitForm: FormGroup;

	curentReferenceTime: string = 'Analysis time';
	referenceTimeList: string[] = ['Analysis time', 'Sampling time'];
	referenceTimeForm: FormGroup;

	machineTags: string[] = [];
	machines: any[] = [];
	machineForm: FormGroup;

	machineDropDownList = [];
	selectedMachines = [];
	machineDropDownSettings = {
		singleSelection: false,
		idField: 'machines_tag_drop',
		textField: 'Machine tag',
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		allowSearchFilter: true
	};

	SamplingPointsDropDownList = [];
	selectedSamplingPoints = [];
	SamplingPointsDropDownSettings = {
		singleSelection: false,
		idField: 'sampling_points_tag_drop',
		textField: 'Sampling points',
		selectAllText: 'Select All',
		unSelectAllText: 'UnSelect All',
		allowSearchFilter: true
	};

	samplingPointTags: string[] = [];
	samplingPoints: any[] = [];
	samplingPointForm: FormGroup;

	startDateForm: FormGroup;
	startTimeForm: FormGroup;
	endDateForm: FormGroup;
	endTimeForm: FormGroup;
	currentStartDate: Date;
	currentStartTime: string;
	currentEndDate: Date;
	currentEndTime: string;

	oldParameterValue: boolean = true;

	ug_chart: CustomChartData;

	constructor(
		private formBuilder: FormBuilder,
		private service: DashboardAnalysisService,
		private snackbar: MatSnackBar,
		private chartService: FullscreenChartService
	) {
	}

	ngOnInit(): void {
		//Unit Selector
		this.unitForm = this.formBuilder.group({
			unitList: [this.currentUnit, [Validators.required]],
		});
		this.referenceTimeForm = this.formBuilder.group({
			referenceTimeList: [this.curentReferenceTime, [Validators.required]],
		});
		this.currentStartDate = moment().subtract(1, 'weeks').toDate()
		this.currentStartTime = '00:00:00'
		this.currentEndDate = moment().toDate()
		this.currentEndTime = '00:00:00'

		this.startDateForm = this.formBuilder.group({
			startDate: [this.currentStartDate]
		});
		this.startTimeForm = this.formBuilder.group({
			startTime: [this.currentStartTime, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]]
			});
		this.endDateForm = this.formBuilder.group({
			endDate: [this.currentEndDate]
		});
		this.endTimeForm = this.formBuilder.group({
			endTime: [this.currentEndTime, [Validators.required, Validators.pattern('[0-9]{2}:[0-9]{2}:[0-9]{2}')]]
		});

		this.service.getMachines().subscribe(data => {
			this.machines = data;
			this.machineTags = data.map(m => m.machine_tag);
			this.machineDropDownList = this.machineTags;
		}, error => {
			this.snackbar.open('Une erreur est survenue. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});

		this.service.getSamplingPoints().subscribe(data => {
			this.samplingPoints = data;
			this.samplingPointTags = data.map(sp => sp.sample_point_tag);
			this.SamplingPointsDropDownList = this.samplingPointTags;
		}, error => {
			this.snackbar.open('Une erreur est survenue. Code: ' + error.status, 'Ok', {
				duration: 5000
			});
		});

		this.machineForm = this.formBuilder.group({
			machineTag: ['-- All --']
		});


		this.samplingPointForm = this.formBuilder.group({
			samplingPointTag: ['-- All --']
		});
		this.setChart()
	}

	unitChanged($event: any) {
		if(this.currentUnit != $event.value){
			this.currentUnit= $event.value;
			if(this.currentUnit == 'UG/L'){
				for (const data of this.dataSource.filteredData){
					data.ug_value *=1000
				}
			} else {
				for (const data of this.dataSource.filteredData){
					data.ug_value /=1000
				}
			}
			this.setChart()
		}
	}

	referenceTimeChanged($event: any) {
		if(this.curentReferenceTime != $event.value){
			this.curentReferenceTime= $event.value;
			this.setChart()
		}
	}

	startDateChange($event: any) {
		if(this.currentStartDate != $event.value){
			this.currentStartDate = $event.value;
			this.oldParameterValue = false;
		}
	}

	startTimeChange($event: any) {
		if(this.currentStartTime != $event){
			this.currentStartTime = $event;
			this.oldParameterValue = false;
		}
	}

	endDateChange($event: any) {
		if(this.currentEndDate != $event.value){
			this.currentEndDate = $event.value;
			this.oldParameterValue = false;
		}
	}

	endTimeChange($event: any) {
		if(this.currentEndTime != $event){
			this.currentEndTime = $event;
			this.oldParameterValue = false;
		}
	}

	async fetchAnalysis() {
		this.oldParameterValue = true;
		this.analysisLoading = true;
		const startString = moment(this.currentStartDate).format('YYYY-MM-DD') + '%20' + this.currentStartTime
		const endString = moment(this.currentEndDate).format('YYYY-MM-DD') + '%20' + this.currentEndTime
		this.analysisList = [];
		const selectedMachineIDs = [];
		const selectedSamplingPointIDs = [];
		for (const selectedMachine of this.selectedMachines) {
			const machine = this.machines.find(m => m.machine_tag === selectedMachine)
			selectedMachineIDs.push(machine.machine_id)
		}
		for (const selectedSamplingPoint of this.selectedSamplingPoints) {
			const samplingPoint = this.samplingPoints.find(sp => sp.sample_point_tag === selectedSamplingPoint)
			selectedSamplingPointIDs.push(samplingPoint.sample_point_id)
		}
		const analyses = await this.service.getAnalysis(selectedMachineIDs, selectedSamplingPointIDs, startString, endString);
		if(analyses != null){
			const analyses_id = analyses.map(o => {return o.analysis_id})
			const analyses_detail = await this.service.getAnalyseDetails(analyses_id)
			for (let a of analyses_detail){
				this.analysisList.push({
					analysis_id: a.map.analysis_id,
					analysis_time: a.map.analysis_time,
					machine_tag: a.map.machine_tag,
					sampling_point_tag:a.map.sampling_name,
					sampling_time: a.map.sampling_time,
					ug_value: a.map?.result_value_ug_rounded?.map?.value,
					risk_lvl:a.map?.result_risk_level?.map?.value
				})
			}
		}
		this.dataSource = new MatTableDataSource(this.analysisList);
		this.analysisLoading = false;
		this.setChart()
	}

	setChart() {
		this.createChart(new UgChartFactory(this.chartService));
	}

	/* Factory Pattern
	* Chart creation from a concrete factory through its base interface */
	async createChart(facto: ChartFactory) {
		const options ={
			referenceTime: this.curentReferenceTime,
			unit: this.currentUnit
		}
		this.ug_chart = await facto.createChart(this.analysisList, options)
	}

	exportToCSV(): void {
		let header = [
			'analysis_id',
			'analysis_time',
			'machine_tag:',
			'sampling_point_tag',
			'sampling_time',
			'ug_value(' + this.currentUnit + ')',
			'risk_lvl'
		];
		const options = {
			filename: 'CsvExport ' + this.getNowTimeStamp(), // TimeStamp in string to create easily identifiable CSV.
			fieldSeparator: ',',
			quoteStrings: '',
			decimalSeparator: '.',
			showLabels: true,
			useTextFile: false,
			useBom: true,
			headers: header
		};

		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(JSON.stringify(this.analysisList));
	}

	sortData(sort: Sort) {
		const data = this.analysisList.slice();
		if (!sort.active || sort.direction === '') {
			this.analysisList = data;
			return;
		}
		this.dataSource = new MatTableDataSource(data.sort((a, b) => {
			const isAsc = sort.direction === 'asc';
			switch (sort.active) {
				case 'machine_tag':
					return compare(a.machine_tag, b.machine_tag, isAsc);
				case 'sampling_point_tag':
					return compare(a.sampling_point_tag, b.sampling_point_tag, isAsc);
				case 'analysis_time':
					return compare(a.analysis_time, b.analysis_time, isAsc);
				case 'sampling_time':
					return compare(a.sampling_time, b.sampling_time, isAsc);
				case 'ug_value':
					return compare(a.ug_value, b.ug_value, isAsc);
				case 'risk_lvl':
					return compare(a.risk_lvl, b.risk_lvl, isAsc);
				default:
					return 0;
			}
		}));
	}

	selectChange(item: any) {
		this.oldParameterValue = false
	}

	// Returns string timestamp for CSV title
	getNowTimeStamp(): string {
		const now = new Date();
		const minutes = '000' + now.getMinutes();
		return now.toDateString() + '_' + now.getHours() + 'h' + minutes.substr(minutes.length - 2); // Last part adds a 0 to the minutes: 8h1 -> 8h01.
	}

	compareCategoryObjects(object1: any, object2: any) {
		return object1 && object2 && object1.id == object2.id;
	}
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
