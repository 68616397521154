<div style="height: auto;">
    <mat-toolbar color="primary" >
        <mat-toolbar-row >
            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm *ngIf="nav._loggedIn">
                <mat-icon>menu</mat-icon>
            </button>

            <span class="example-spacer"></span>
            <div fxShow="true" fxHide.lt-md="true">
								<a href="/dashboards/machines" mat-button *ngIf="nav._loggedIn && checkForPermission()" style="font-size: unset">
									<mat-icon><img style="width: 100%" src="favicon.ico"></mat-icon>
									BioAlert Solutions
								</a>
								<a href="/dashboards/machines" mat-button *ngIf="nav._loggedIn && !checkForPermission()" style="font-size: unset">
									<mat-icon><img style="width: 100%" src="favicon.ico"></mat-icon>
									BioAlert Solutions
								</a>
                <a href="/dashboards/machines" mat-button *ngIf="nav._loggedIn && checkForPermission()">
                    <mat-icon>settings</mat-icon>
										Machines
                </a>
								<a href="/dashboards/analysis" mat-button *ngIf="nav._loggedIn && checkForPermission()">
										<mat-icon>query_stats</mat-icon>
										Analysis
								</a>
            </div>
            <span class="spacer"></span>
            <button mat-icon-button (click)="userPanel.toggle()" fxShow="true" *ngIf="nav._loggedIn">
                <mat-icon>account_circle</mat-icon>
            </button>

        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container fxFlexFill>
        <mat-sidenav #userPanel position="end">
            <mat-nav-list fxLayout="column" fxLayoutAlign="start start">
                <a mat-button href="/account">
                    <mat-icon>settings</mat-icon>
                    Account settings
                </a>
                <a mat-button (click)="signOut(); userPanel.toggle()">
                    <mat-icon>logout</mat-icon>
                    Sign out
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>
            <router-outlet></router-outlet>
        </mat-sidenav-content>

        <mat-sidenav #sidenav>
            <mat-nav-list>
								<a href="/dashboards/machines" mat-list-item>
										<mat-icon>settings</mat-icon>
										Machines
								</a>
								<a href="/dashboards/analysis" *ngIf="checkForPermission()"  mat-list-item>
										<mat-icon>query_stats</mat-icon>
										Analysis
								</a>
                <a (click)="sidenav.toggle()" mat-list-item>
                    <mat-icon>close</mat-icon>
                    Close
                </a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content fxFlexFill>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>

