import {Chart} from '../ChartProduct/Chart';

/* Machine chart Factory */

export abstract class ChartFactory {

	constructor() {};

	public abstract factoryMethod(): Chart;

	/**
	 * Also note that, despite its name, the Creator's primary responsibility is
	 * not creating products. Usually, it contains some core business logic that
	 * relies on Product objects, returned by the factory method. Subclasses can
	 * indirectly change that business logic by overriding the factory method
	 * and returning a different type of product from it.
	 */
	public createChart(data: any, option: any, ): any {
		// Call the factory method to create a Product object.
		const m = this.factoryMethod();
		// Now, use the product.
		return m.createChart(data, option);
	}
}
