<mat-card>
	<!--<h2>Êtes-vous certain de vouloir faire les modifications suivantes?</h2>-->
	<h2>Are you sure you want to do this ?</h2>
	<mat-card-content *ngFor="let modif of data">
		<h3>{{modif.mod_description}}</h3>
	</mat-card-content>

	<mat-card-actions fxLayoutAlign="end end">
		<button style="margin-right: 10px" mat-raised-button color="basic" [mat-dialog-close]="false">No</button>
		<button mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
	</mat-card-actions>

</mat-card>
