import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import {NavbarService} from "./header/navbar.service";

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private history: string[] = [];

    constructor(private router: Router, private location: Location, public nav: NavbarService) {
    }

    back(): void {
        this.history.pop();
        if (this.history.length > 0) {
            this.location.back();
        } else {
					this.router.navigateByUrl('/dashboards/machines');
        }
    }

    push(): void{
        this.history.push(this.router.url);
    }
}
