import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	NavigationEnd,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {CookieServiceService} from './cookie-service.service';
import {NavbarService} from "./header/navbar.service";
import {NavigationService} from "./navigation-service.service";
import {LocalStorageService} from "./local-storage.service";
import jwt_decode from "jwt-decode";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private _router: Router, public nav: NavbarService, public cookieService: CookieServiceService , public localStorageService: LocalStorageService, private navigation: NavigationService) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		const token = this.cookieService.get('token');

		if (token != null) {
			this.navigation.push();
			const decoded = jwt_decode(token);
			this.nav.loggedIn(decoded['role']);
			return true;
		} else {
			this._router.navigate(['/login']);
		}

	}
}
