import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieServiceService} from '../cookie-service.service';
import {environment} from '../../environments/environment';
import {Machine} from "../Models/Machine";
import {SamplingPoint} from "../Models/SamplingPoint";
import {Analysis} from "../Models/Analysis";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root'
})
export class DashboardAnalysisService {

	header;
	apiUrl = environment.apiUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService, private snackbar: MatSnackBar) {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, PATCH, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		});
	}

	getMachines(): Observable<Machine[]> {
		return this.http.get<Machine[]>(this.apiUrl + '/machines', {headers: this.header});
	}

	getSamplingPoints(): Observable<SamplingPoint[]> {
		return this.http.get<SamplingPoint[]>(this.apiUrl + '/samplingPoints', {headers: this.header});
	}

	async getAnalysis(machine_id: number[], sampling_point_id: number[], start: string, end:string) {
		let query = this.apiUrl + '/analyses?'
		query += 'after=' + start + '&before=' + end;

		if (machine_id.length == 0 && sampling_point_id.length == 0) {
			this.snackbar.open('Please select a Machine or a sampling point', 'Ok', {
				duration: 5000
			});
			return null;
		}
		if (machine_id.length > 0) {
			query += '&machine_id=' + machine_id.join(',');
		}
		if (sampling_point_id.length > 0) {
			query += '&sample_point_id=' + sampling_point_id.join(',');
		}
		return this.http.get<Analysis[]>(query, {headers: this.header}).toPromise();
	}

	async getAnalyseDetails(analysis_id: number[]) {
		const analysis = analysis_id.join(',')
		return await this.http.get<any[]>(this.apiUrl + '/analyses/' + analysis, {headers: this.header}).toPromise();
	}
}
