import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {LayoutModule} from '@angular/cdk/layout';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatStepperModule} from '@angular/material/stepper';
import {MachineListComponent} from './dashboard-machine/machine-list/machine-list.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatExpansionModule} from '@angular/material/expansion';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js/dist/plotly.js';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {AuthGuard} from './auth.guard';
import {LoginComponent} from './login/login.component';
import {EditApprovalDialogComponent} from './account-page/edit-approval-dialog/edit-approval-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {A11yModule} from '@angular/cdk/a11y';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {AuthInterceptorProvider} from './auth.interceptor';
import {HeaderComponent} from './header/header.component';
import { AccountPageComponent } from './account-page/account-page.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AnalysisListComponent} from './dashboard-analysis/analysis-list/analysis-list.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ISO_DATE_FORMATS } from './custom-date-formats';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
	declarations: [
		AnalysisListComponent,
		AppComponent,
		EditApprovalDialogComponent,
		LoginComponent,
		MachineListComponent,
		HeaderComponent,
		AccountPageComponent
	],
  imports: [
		A11yModule,
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		DragDropModule,
		FlexLayoutModule,
		FormsModule,
		HttpClientModule,
		LayoutModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatSelectModule,
		MatSidenavModule,
		MatSnackBarModule,
		MatSortModule,
		MatStepperModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		PlotlyModule,
		ReactiveFormsModule,
		SocialLoginModule,
		MatProgressSpinnerModule,
		NgxMatSelectSearchModule,
		MatSlideToggleModule,
		MomentDateModule,
		NgMultiSelectDropDownModule,
	],
	providers: [
		AuthInterceptorProvider,
		AuthGuard,
		{ provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							'803281504540-8umn5hrb6eu4ata98veum1nqrm13t04k.apps.googleusercontent.com'
						),
					}
				]
			} as SocialAuthServiceConfig,
		},
		{ provide: MAT_DATE_FORMATS, useValue: ISO_DATE_FORMATS}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }

