import {Component, OnInit} from '@angular/core';
import {DashboardMachineService} from '../dashboard-machine.service';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';
import {NavbarService} from "../../header/navbar.service";

@Component({
	selector: 'app-machine-list',
	templateUrl: './machine-list.component.html',
	styleUrls: ['./machine-list.component.css'],
})
export class MachineListComponent implements OnInit {
	MachineLoading: boolean = true;

	ascIdMachine: boolean = true;
	orderedByIdMachine: boolean = true;
	ascDaysWithoutAnalysis: boolean = false;
	orderedByDaysWithoutAnalysis: boolean = false;
	ascCartDisp: boolean = false;
	orderedByCartDisp: boolean = false;

	machinesList: any[] = [];

	constructor(
		private service: DashboardMachineService,
		public dialog: MatDialog,
		public nav: NavbarService,
	) {
	}

	ngOnInit() {
		this.service.getMachines().subscribe(machines => {
			this.machinesList = machines
			this.loadDashboardFlags(this.machinesList).then(() => this.MachineLoading = false);
		})
	}

	async loadDashboardFlags(machines: any[]) {
		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

		for (const machine of machines) {

			let daysWithoutAnalysis = null;
			const lastAnalysis = await this.service.getLastAnalysis(machine.machine_id);
			const lastAnalysisTime = lastAnalysis?new Date(lastAnalysis.analysis_time):null;
			const lastAnalysisId= lastAnalysis?lastAnalysis.analysis_id:null;
			const nextMaintenance = await this.service.getNextMaintenanceDate(machine.machine_id, machine.last_maintenance, machine.nb_analysis_before_maintenance);

			if (lastAnalysisTime != null) {
				daysWithoutAnalysis = Math.round(Math.abs((lastAnalysisTime.getTime() - new Date().getTime()) / oneDay));
			}

			let cart_available = null;
			if (lastAnalysisId != null) {
				let variables = await this.service.getVariableValue(lastAnalysisId, [20]);
				for (let variable of variables){
					if (variable.map.var_id == 20) {
						cart_available = variable.map.value
					}
				}
			}

			machine.days_without_analysis = daysWithoutAnalysis;
			machine.cartouches_disponibles = cart_available;
			machine.daysNextMaintenance = nextMaintenance.daysNextMaintenance;
			machine.nextMaintenanceDate = nextMaintenance.nextMaintenanceDate;
			machine.flagsLoaded = true;
		}
	}

	orderByMachineTag() {
		if (this.ascIdMachine) {
			this.machinesList = _.orderBy(this.machinesList, ['machine_id'], ['desc']);
		} else {
			this.machinesList = _.orderBy(this.machinesList, ['machine_id'], ['asc']);
		}
		this.ascIdMachine = !this.ascIdMachine;

		this.orderedByIdMachine = true;
		this.orderedByDaysWithoutAnalysis = false;
		this.orderedByCartDisp = false;
	}

	orderByDaysWithoutAnalysis() {

		if (this.ascDaysWithoutAnalysis) {
			this.machinesList = _.orderBy(this.machinesList, ['days_without_analysis', 'machine_tag'], ['desc', 'asc']);
		} else {
			this.machinesList = _.orderBy(this.machinesList, ['days_without_analysis', 'machine_tag'], ['asc', 'asc']);
		}
		this.ascDaysWithoutAnalysis = !this.ascDaysWithoutAnalysis;

		this.orderedByIdMachine = false;
		this.orderedByDaysWithoutAnalysis = true;
		this.orderedByCartDisp = false;
	}

	orderByCartDisp() {
		if (this.ascCartDisp) {
			this.machinesList = _.orderBy(this.machinesList, ['cartouches_disponibles', 'machine_tag'], ['desc', 'asc']);
		} else {
			this.machinesList = _.orderBy(this.machinesList, ['cartouches_disponibles', 'machine_tag'], ['asc', 'asc']);
		}
		this.ascCartDisp = !this.ascCartDisp;

		this.orderedByIdMachine = false;
		this.orderedByDaysWithoutAnalysis = false;
		this.orderedByCartDisp = true;
	}

	checkForPermission(): boolean{
		if (this.nav._role === 'admin' || this.nav._role === 'super-admin'){
			return true;
		}else {
			return false;
		}
	}
}
