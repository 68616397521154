<body>
	<mat-card class="dashboard-analysis-header">
		<mat-card-title fxLayoutAlign="space-around">
			<h2>Analysis dashboard</h2>
		</mat-card-title>

		<mat-form-field class="mat-sel" appearance="fill" [formGroup]="unitForm">
			<mat-label>Unit</mat-label>
			<mat-select (selectionChange)="unitChanged($event)" formControlName="unitList">
				<mat-option *ngFor="let unit of unitList" [value]="unit">
					{{unit}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="mat-sel" appearance="fill" [formGroup]="referenceTimeForm">
			<mat-label>Reference Time</mat-label>
			<mat-select (selectionChange)="referenceTimeChanged($event)" formControlName="referenceTimeList">
				<mat-option *ngFor="let referenceTime of referenceTimeList" [value]="referenceTime">
					{{referenceTime}}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<div class="plotly-chart" *ngIf="ug_chart">
			<plotly-plot
				[data]="ug_chart.chartData"
				[layout]="ug_chart.chartLayout"
				[config]="ug_chart.chartConfig">
			</plotly-plot>
		</div>

		<div class="multiselect-dropdown">
			<mat-label>Machines</mat-label>
			<ng-multiselect-dropdown
				[placeholder]="'select'"
				[settings]="machineDropDownSettings"
				[data]="machineDropDownList"
				[(ngModel)]="selectedMachines"
				(onSelect)="selectChange($event)"
				(onDeSelect)="selectChange($event)"
				(onSelectAll)="selectChange($event)"
				(onDeSelectAll)="selectChange($event)"
			>
			</ng-multiselect-dropdown>
		</div>

		<div class="multiselect-dropdown">
			<mat-label>Sampling Points</mat-label>
			<ng-multiselect-dropdown
				[placeholder]="'select'"
				[settings]="SamplingPointsDropDownSettings"
				[data]="SamplingPointsDropDownList"
				[(ngModel)]="selectedSamplingPoints"
				(onSelect)="selectChange($event)"
				(onDeSelect)="selectChange($event)"
				(onSelectAll)="selectChange($event)"
				(onDeSelectAll)="selectChange($event)"
			>
			</ng-multiselect-dropdown>
		</div>

		<mat-form-field color="primary" appearance="fill" [formGroup]="startDateForm">
			<mat-label>Start Date</mat-label>
			<input matInput [matDatepicker]="picker1" formControlName="startDate" (dateChange)="startDateChange($event)">
			<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
			<mat-datepicker #picker1 color="primary"></mat-datepicker>
		</mat-form-field>

		<mat-form-field appearance="fill" [formGroup]="startTimeForm">
			<mat-label>Start Time</mat-label>
			<input matInput placeholder="HH-MM-SS" type="text" formControlName="startTime" required (ngModelChange)="startTimeChange($event)">
		</mat-form-field>

		<mat-form-field color="primary" appearance="fill" [formGroup]="endDateForm">
			<mat-label>End Date</mat-label>
			<input matInput [matDatepicker]="picker2" formControlName="endDate" (dateChange)="endDateChange($event)">
			<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
			<mat-datepicker #picker2 color="primary"></mat-datepicker>
		</mat-form-field>

		<mat-form-field appearance="fill" [formGroup]="endTimeForm">
			<mat-label>End Time</mat-label>
			<input matInput placeholder="HH-MM-SS" type="text" formControlName="endTime" required (ngModelChange)="endTimeChange($event)">
		</mat-form-field>

		<button mat-raised-button color="primary" (click)="fetchAnalysis()" [disabled]="oldParameterValue">
			Search
		</button>

		<button (click)="exportToCSV()" color="primary" mat-raised-button [disabled]="!oldParameterValue || analysisLoading">
			Export data
		</button>

		<mat-progress-bar *ngIf="analysisLoading" mode="indeterminate" class="progress-bar"></mat-progress-bar>
		<mat-divider></mat-divider>
		<table mat-table [dataSource]="dataSource"
					 class="card-right-body-table" matSort (matSortChange)="sortData($event)">
			<ng-container matColumnDef="analysis_id">
				<th mat-header-cell *matHeaderCellDef> Analysis id</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="element.analysis_id; else defaultValue">
						{{element.analysis_id}}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="machine_tag">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="machine_tag"> Machine</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="element.machine_tag; else defaultValue">
						{{element.machine_tag}}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="sampling_point_tag">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="sampling_point_tag"> Sampling point</th>
				<td mat-cell *matCellDef="let element">
					<ng-container *ngIf="element.sampling_point_tag; else defaultValue">
						{{element.sampling_point_tag}}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="analysis_time">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="analysis_time"> Analysis date</th>
				<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.analysis_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
					<ng-container *ngIf="element.analysis_time; else defaultValue">
						{{element.analysis_time | date:'yyyy-MM-dd HH:mm'}}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="sampling_time">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="sampling_time"> Sampling date</th>
				<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.sampling_time?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
					<ng-container *ngIf="element.sampling_time; else defaultValue">
						{{element.sampling_time | date:'yyyy-MM-dd HH:mm'}}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="ug_value">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="ug_value"> UG concentration ({{currentUnit}})</th>
				<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.ug_value?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
					<ng-container *ngIf="element.ug_value; else defaultValue">
						{{element.ug_value}}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="risk_lvl">
				<th mat-header-cell *matHeaderCellDef mat-sort-header="risk_lvl"> Risk level</th>
				<td mat-cell *matCellDef="let element" [ngStyle]="
              !element.ug_value?{'background-color': '#EEEEEE'}:
              {'background-color': '#FFFFFF'}">
					<ng-container *ngIf="element.risk_lvl; else defaultValue">
						{{element.risk_lvl}}
					</ng-container>
				</td>
			</ng-container>

			<ng-template #defaultValue>No Data</ng-template>
			<mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</table>
	</mat-card>
</body>
