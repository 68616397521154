import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CookieServiceService} from '../cookie-service.service';
import {ApiMsg} from '../Models/ApiMsg';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	header;
	authUrl = environment.authUrl;

	constructor(private http: HttpClient, private cookieService: CookieServiceService) {
	}

	public signInWithMicroService(email: string, password: string): Observable<any> {
		this.header = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + this.cookieService.get('token'),
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'DELETE, GET, POST, OPTIONS',
			'Access-Control-Allow-Headers': 'Origin, Content-Type, Authorization',
		});
		return this.http.post<any>(this.authUrl + "/auth/signIn", {"email": email, "password": password}, {headers: this.header});
	}
}
