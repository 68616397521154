import {FullscreenChartService} from '../../../fullscreen-chart/fullscreen-chart.service';
import {CustomChartData} from '../../CustomChartData';
import {Chart} from '../Chart';

/* Concrete Chart for Factory Pattern */
export class UgChart implements Chart {
	datas: CustomChartData;

	private config = {
		responsive: true,
		displaylogo: false
	};

	private layout = {
		// items with a `name` attribute in template.images will be added to any
		// plot using this template
		width: 1400,
		height: 800,
		images: [{
			source: '../../../../favicon.ico',
			xref: 'paper',
			yref: 'paper',
			x: 0.5,
			y: 0.5,
			xanchor: 'center',
			yanchor: 'middle',
			sizex: 0.7,
			sizey: 0.7,
			opacity: 0.1,
			layer: 'below'
		}],
		legend: {
			x: 1.01,
			y: 0.99,
			itemsizing: 'constant',
			itemwidth: 25
		},
		margin: {
			l: 135,
			r: 135,
			b: 65,
			t: 10
		},
		xaxis: {
			title:{
				text:'',
				font: {
					size: 24
				}
			},
			tickformat: '%d %b',
			tickangle: -45,
			showgrid: true,
			nticks: 36,
			showline: true,
			zeroline: false,
			mirror: true,
			hoverformat: '%Y-%m-%d %H:%M:%S'
		},
		yaxis: {
			title:{
				text:'',
				font: {
					size: 24
				},
				standoff: 2
			},
			tickformat: ' ',
			showgrid: true,
			showline: true,
			zeroline: false,
			mirror: true
		},
		modebar: {
			color: '#000000',
			bgcolor: '#FFFFFF'
		},
	};


	constructor(private service: FullscreenChartService) {
	}

	public async createChart(analysisList: any, option: any): Promise<CustomChartData> {
		this.layout.xaxis.title.text = option.referenceTime;
		this.layout.yaxis.title.text = option.unit;
		this.datas = new CustomChartData([], this.layout, this.config);

		const traces_ug = [];
		const sampling_point_tag = [];
		for (const analysis of analysisList) {
			if(!sampling_point_tag.includes(analysis.sampling_point_tag)) {
				sampling_point_tag.push(analysis.sampling_point_tag)
				let time;
				if(option.referenceTime == 'Sampling time'){
					time = analysis.sampling_time;
				} else {
					time = analysis.analysis_time;
				}
				traces_ug.push({
					name: analysis.sampling_point_tag,
					y: [analysis.ug_value],
					x: [time],
					type: 'scatter',
					marker: {
						size: 10,
					}
				})
			} else {
				const index = sampling_point_tag.indexOf(analysis.sampling_point_tag)
				traces_ug[index].y.push(analysis.ug_value)
				if(option.referenceTime == 'Sampling time'){
					traces_ug[index].x.push(analysis.sampling_time)
				} else {
					traces_ug[index].x.push(analysis.analysis_time)
				}
			}
		}

		this.datas.chartData = traces_ug;
		return this.datas;
	}
}


